<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">{{ title }}</h4>

        <div class="page-title-right breadcrumb-responsive">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>


<style>
.breadcrumb-responsive {
  display: block;
}


@media (max-width: 992px) {
  .breadcrumb-responsive {
    display: block;
  }

}

@media (max-width: 576px) {
  .breadcrumb-responsive {
    display: none;
  }
}
</style>