<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import {dateUtil} from "@/helpers/date-util";
import {paginationHelper} from "@/helpers/pagination-helper";
import axios from "axios";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";


/**
 * Withdrawals Component
 */
export default {

  computed: {

    paginationHelper() {
      return paginationHelper
    }

  },

  components: {

    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      withdrawals: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        items: [],
        isBusy: false
      }

    };
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.withdrawals, 'withdrawalsTable')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core.withdrawals.title'),
          active: true
        }
      ]
    },

    getWithdrawalsFields() {
      return [
        {key: "shopName", label: 'Sklep'},
        {key: "firstName", label: "Imię"},
        {key: "lastName", label: "Nazwisko"},
        {key: "companyName", label: "Firma"},
        {
          key: "amount", label: 'Kwota', formatter: value => {
            if (!value) {
              return "-"
            }

            return value.toFixed(2) + " PLN"
          }
        },
        {key: "accountNumber", label: "Numer konta"},
        {key: "status", slot: true, label: 'Status'},
        {key: "createdAt", label: 'Data', formatter: value => dateUtil.asDateTime(value)},
        {key: "actions", slot: true, label: this.$t("table.actions")}
      ]
    },

    async loadWithdrawals() {
      let page = this.withdrawals.currentPage - 1;
      if (page > 0) {
        page = this.withdrawals.currentPage * this.withdrawals.perPage - this.withdrawals.perPage;
      }

      const result = await axios.get(`/ewallet/withdrawal/pagination/all`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.withdrawals.perPage,
          "statuses": ["PENDING", "VERIFIED", "ACCEPTED"].join(',')
        }
      });

      this.withdrawals.items = result.data.withdrawals;
      this.withdrawals.totalRows = result.data.count;
      this.withdrawals.rows = result.data.count;

      if (this.mounted) {
        this.$refs.withdrawalsTable.refresh()
      }

      return this.withdrawals.items;
    },

    deleteWithdrawal(withdrawal) {
      axios.delete(`/ewallet/withdrawal/${withdrawal.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      }).then(() => {
        Swal.fire("Sukces!", "Wypłata została usunięta!", "success").then(() => {
          this.$refs.withdrawalsTable.refresh()
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    changeStatus(withdrawal, status) {
      const json = JSON.stringify({
        "status": status
      })

      axios.post(`/ewallet/withdrawal/${withdrawal.id}`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      }).then(() => {
        Swal.fire("Sukces!", "Status został zmieniony!", "success").then(() => {
          this.$refs.withdrawalsTable.refresh()
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('core.withdrawals.title')"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ecat-table
                ref="withdrawalsTable"
                :table="withdrawals"
                :fields="getWithdrawalsFields()"
                :items="loadWithdrawals"
                :pagination-top="true"

            >
              <template v-slot:status="{ item }">
                <span class="badge" :class="$t(`e-wallet.withdrawals.status-badge.${item.status}`)">{{
                    $t(`e-wallet.withdrawals.status.${item.status}`)
                  }}</span>
              </template>

              <template v-slot:actions="{ item }">
                <div class="button-items">
                  <a v-if="item.invoiceFileUrl" class="btn btn-primary btn-sm" :href="item.invoiceFileUrl">Pobierz
                    fakturę</a>
                  <b-button variant="success" class="btn-sm" v-if="item.status !== 'VERIFIED'"
                            @click="changeStatus(item, 'VERIFIED')">Zweryfikuj
                  </b-button>
                  <b-button variant="success" class="btn-sm" v-if="item.status === 'VERIFIED'"
                            @click="changeStatus(item, 'COMPLETED')">Zatwierdź
                  </b-button>
                  <b-button variant="danger" class="btn-sm" @click="deleteWithdrawal(item)">Usuń</b-button>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>